import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map } from "rxjs/operators";
import { NotificationType } from "../../models/core/notification-types";
import * as coreSharedActions from "../../state/actions/core-shared.actions";

import { sharedActions } from "../actions";

@Injectable()
export class SharedEffects {
  errorOccurred$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sharedActions.errorOccurred),
      map((action) => {
        const error = action.error;
        let returnAction: any;
        let errMessage = "";
        switch (error.status) {
          case 400:
            error.error.attributeErrors.forEach((errorItem: any) => {
              errMessage += `${errorItem.attributeDisplayMessage} \n`;
            });
            returnAction = coreSharedActions.onNotificationReq({
              message: errMessage,
              notificationType: NotificationType.ERROR,
            });
            break;
          case 403:
            returnAction = coreSharedActions.onNotificationReq({
              message: error.error,
              notificationType: NotificationType.ERROR,
            });
            break;
          case 404:
            returnAction = coreSharedActions.onNotificationReq({
              message: error.statusText,
              notificationType: NotificationType.ERROR,
            });
            break;
          default:
            returnAction = coreSharedActions.onNotificationReq({
              message: error.error.developerMessage,
              notificationType: NotificationType.ERROR,
            });
        }
        return returnAction;
      })
    )
  );

  doNothing$ = createEffect(
    () => this.actions$.pipe(ofType(sharedActions.doNothing)),
    { dispatch: false }
  );
  constructor(private actions$: Actions) {}
}
