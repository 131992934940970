import { Injectable } from "@angular/core";
import * as coreUiExtensionActions from "@modules/core-ui-extensions/actions";
import { CoreUiExtensionsStateService } from "@modules/core-ui-extensions/services";
import { deepCopy } from "@modules/electronic-file-folder/helpers";
import { ContentSummaryEventService } from "@modules/electronic-file-folder/services/content-summary/analytics/content-summary-event/content-summary-event.service";
import { ContentSummaryService } from "@modules/electronic-file-folder/services/content-summary/content-summary/content-summary.service";
import { StopwatchUtil } from "@modules/shared";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { concatLatestFrom } from "@ngrx/operators";
import { Action, Store } from "@ngrx/store";
import { asyncScheduler, from, of } from "rxjs";
import {
  catchError,
  observeOn,
  switchMap,
  withLatestFrom,
} from "rxjs/operators";
import {
  ContentSummaryActions,
  CoreActions,
  RequestThatErrored,
} from "../../actions";
import { EFFConstants, SearchTypeValues } from "../../constants";
import {
  IApiErrorResponse,
  IAppState,
  IContentDetailsGetRequest,
  IContentSummarySearchResponse,
  IPartyPropertyInfo,
} from "../../models";
import { ContentSummaryMappers } from "../../utilities";

@Injectable()
export class ContentSummaryEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<IAppState>,
    private contentSummaryService: ContentSummaryService,
    private contentSummaryEventService: ContentSummaryEventService,
    private coreUiStateService: CoreUiExtensionsStateService
  ) {}

  public detectContentDetailsRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContentSummaryActions.onContentDetailsRequested),
      withLatestFrom(this.coreUiStateService.repSummaries$),
      switchMap(([action, repSummaries]) => {
        return this.contentSummaryService
          .getContentDetails(action.request)
          .pipe(
            switchMap((response: IContentSummarySearchResponse) =>
              from([
                ContentSummaryActions.onContentDetailsReceived({
                  response: {
                    claimNumber: action.request.claimNumber,
                    ...response,
                    contentItems:
                      ContentSummaryMappers.formatContentSummaryResponse(
                        response,
                        repSummaries
                      ),
                  },
                }),
                coreUiExtensionActions.CoreUiExtensionsActions.onGetRepSummariesRequest(
                  {
                    codes: [
                      ...new Set(
                        response.contentItems.flatMap((x) =>
                          [x.createdByUserId].filter((x) => x)
                        )
                      ),
                    ],
                  }
                ),
              ])
            ),
            catchError((error: any) =>
              of(ContentSummaryActions.onContentDetailsError(error))
            )
          );
      })
    )
  );

  public updateFiltersWithRepInfo$ = createEffect(() =>
    this.coreUiStateService.repSummaries$.pipe(
      concatLatestFrom(() => this.store$),
      switchMap(([repSummaries, state]) => {
        const storedResponse = deepCopy(
          state.contentSummary.contentDetails.response
        );
        if (storedResponse) {
          const formattedContentItems =
            ContentSummaryMappers.formatContentSummaryResponse(
              storedResponse,
              repSummaries
            );
          const contentDetails = {
            ...storedResponse,
            contentItems: formattedContentItems,
          };
          return of(
            ContentSummaryActions.onContentDetailsRefreshReceived({
              response: contentDetails,
            })
          );
        }
        return of(ContentSummaryActions.onContentDetailsNoop());
      })
    )
  );

  public detectContentSummarySearchReceived$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContentSummaryActions.onContentSummarySearchReceived),
      observeOn(asyncScheduler),
      withLatestFrom(this.store$),
      switchMap(([, state]) => {
        const filterResult = ContentSummaryMappers.filterItems(
          state.contentSummary
        );
        return of(ContentSummaryActions.onContentSummaryFilter(filterResult));
      })
    )
  );

  public detectContentSummarySearchRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContentSummaryActions.onContentSummarySearchRequested),
      observeOn(asyncScheduler),
      withLatestFrom(this.store$),
      switchMap(([action, state]) => {
        if (action.searchType === SearchTypeValues.Content) {
          const claimNumber: string = (state as any).claimDetails.claimNumber;
          const startTime = new Date().getTime();

          return this.contentSummaryService
            .getSearchContent({
              claimNumber,
              searchText: action.searchTerm,
            })
            .pipe(
              switchMap((response) => {
                // Audit log of how long the search took
                const searchTime = StopwatchUtil.getElapsedSeconds(startTime);
                this.contentSummaryEventService.contentSearch(searchTime);

                const searchResults = (response || []).map(
                  (item) => item.documentSetId
                );

                return of(
                  ContentSummaryActions.onContentSummarySearchReceived({
                    searchResults,
                  })
                );
              }),
              catchError((error: any) => this.onError("ContentSummary", error))
            );
        }

        return of(
          ContentSummaryActions.onContentSummarySearchReceived({
            searchResults: [],
          })
        );
      })
    )
  );

  public detectRefreshRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContentSummaryActions.onContentDetailsRefreshRequested),
      withLatestFrom(this.store$, this.coreUiStateService.repSummaries$),
      switchMap(([, state, repSummaries]) => {
        const request = this.buildRefreshRequest(state);

        return this.contentSummaryService.getContentDetails(request).pipe(
          switchMap((response: IContentSummarySearchResponse) => {
            const formattedContentItems =
              ContentSummaryMappers.formatContentSummaryResponse(
                response as IContentSummarySearchResponse,
                repSummaries
              );
            const contentDetails = {
              ...response,
              contentItems: formattedContentItems,
            };
            return of(
              ContentSummaryActions.onContentDetailsRefreshReceived({
                response: contentDetails,
              })
            );
          }),
          catchError((error: any) => this.onError("ContentSummary", error))
        );
      })
    )
  );

  public detectResetFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContentSummaryActions.onResetFilters),
      observeOn(asyncScheduler),
      withLatestFrom(this.store$),
      switchMap(([, state]) => {
        const filterResult = ContentSummaryMappers.filterItems(
          state.contentSummary
        );
        return of(ContentSummaryActions.onContentSummaryFilter(filterResult));
      })
    )
  );

  public detectToggleColumnFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContentSummaryActions.onToggleColumnFilters),
      observeOn(asyncScheduler),
      withLatestFrom(this.store$),
      switchMap(([, state]) => {
        const filterResult = ContentSummaryMappers.filterItems(
          state.contentSummary
        );
        return of(ContentSummaryActions.onContentSummaryFilter(filterResult));
      })
    )
  );

  public detectToggleDateFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContentSummaryActions.onToggleDateFilter),
      observeOn(asyncScheduler),
      withLatestFrom(this.store$),
      switchMap(([, state]) => {
        const filterResult = ContentSummaryMappers.filterItems(
          state.contentSummary
        );
        return of(ContentSummaryActions.onContentSummaryFilter(filterResult));
      })
    )
  );
  public detectToggleFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContentSummaryActions.onToggleFilter),
      observeOn(asyncScheduler),
      withLatestFrom(this.store$),
      switchMap(([, state]) => {
        const filterResult = ContentSummaryMappers.filterItems(
          state.contentSummary
        );
        return of(ContentSummaryActions.onContentSummaryFilter(filterResult));
      })
    )
  );

  public buildRefreshRequest(state: IAppState): IContentDetailsGetRequest {
    const claimNumber = (state as any).claimDetails.claimNumber;
    let partyProperty = {} as IPartyPropertyInfo;
    let request: IContentDetailsGetRequest = {
      claimNumber,
    } as IContentDetailsGetRequest;
    if (
      state.contentSummary.contentDetails.response &&
      state.contentSummary.contentDetails.response.partyPropertyInfo
    ) {
      partyProperty =
        state.contentSummary.contentDetails.response.partyPropertyInfo;
    }

    if (
      partyProperty &&
      partyProperty.indexModelCode &&
      partyProperty.indexModelId
    ) {
      if (
        partyProperty.indexModelCode ===
        EFFConstants.codes.partyProperty.property
      ) {
        request = {
          ...request,
          propertyId: partyProperty.indexModelId.toString(),
        };
      } else if (
        partyProperty.indexModelCode === EFFConstants.codes.partyProperty.party
      ) {
        request = {
          ...request,
          partyId: partyProperty.indexModelId.toString(),
        };
      }
    }
    return request;
  }

  private onError = (
    requestThatErrored: RequestThatErrored,
    error: any,
    responseMessageMap: { [key: string]: string } = {}
  ) => {
    const actions: Action[] = [
      ContentSummaryActions.onContentSummaryApiError({ requestThatErrored }),
    ];
    let errorMessage = "An unknown error occurred. Please try again later.";
    switch (error.status) {
      case 400: {
        const apiError = (error.error as IApiErrorResponse) || {};

        if (typeof apiError === "string") {
          errorMessage = apiError;
        } else if (
          apiError.errorCode &&
          responseMessageMap[apiError.errorCode]
        ) {
          errorMessage = responseMessageMap[apiError.errorCode];
        } else if (
          apiError.attributeErrors &&
          apiError.attributeErrors.length
        ) {
          errorMessage = apiError.attributeErrors[0].attributeDisplayMessage;
        } else if (apiError.displayMessage) {
          errorMessage = apiError.displayMessage;
        } else if (apiError.developerMessage) {
          errorMessage = apiError.developerMessage;
        }

        // eslint-disable-next-line functional/immutable-data
        actions.unshift(
          CoreActions.onNotificationRequest({
            message: errorMessage,
            notificationType: "ERROR",
          })
        );
        break;
      }
      case 404:
      case 500: {
        // eslint-disable-next-line functional/immutable-data
        actions.unshift(
          CoreActions.onNotificationRequest({
            message: errorMessage,
            notificationType: "ERROR",
          })
        );
        break;
      }
      default: {
        // eslint-disable-next-line functional/immutable-data
        actions.unshift(CoreActions.serverError(error));
      }
    }

    return from(actions);
  };
}
